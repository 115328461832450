exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-artworks-js": () => import("./../../../src/pages/artworks.js" /* webpackChunkName: "component---src-pages-artworks-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-templates-collection-template-jsx": () => import("./../../../src/templates/collectionTemplate.jsx" /* webpackChunkName: "component---src-templates-collection-template-jsx" */),
  "component---src-templates-section-template-jsx": () => import("./../../../src/templates/sectionTemplate.jsx" /* webpackChunkName: "component---src-templates-section-template-jsx" */),
  "component---src-templates-single-painting-page-template-jsx": () => import("./../../../src/templates/singlePaintingPageTemplate.jsx" /* webpackChunkName: "component---src-templates-single-painting-page-template-jsx" */)
}

